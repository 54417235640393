import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const race_id = 12783;
const token = '7d06b481-608a-4a9e-b1a3-7e9d4e9bb18f';

export default new Vuex.Store({
  state: {
    race_id: race_id,
    registration_open: true,
    token: token,
    amount: 1500,
    target: 228000,
    spent: 0,
    api_url: `https://api.reg.place/v1/events/dz-2021/races/${race_id}/heats?token=${token}&ts=${Date.now()}`,
    houses: [
      {number: "1", paid: false},
      {number: "2", paid: false},
      {number: "3", paid: false},
      {number: "4", paid: false},
      {number: "5", paid: false},
      {number: "6", paid: false},
      {number: "7", paid: false},
      {number: "8", paid: false},
      {number: "9", paid: false},
      {number: "10", paid: false},
      {number: "11", paid: false},
      {number: "12", paid: false},
      {number: "13", paid: false},
      {number: "14", paid: false},
      {number: "15", paid: false},
      {number: "16", paid: false},
      {number: "17", paid: false},
      {number: "18", paid: false},
      {number: "19", paid: false},
      {number: "20", paid: false},
      {number: "21", paid: false},
      {number: "22", paid: false},
      {number: "23", paid: false},
      {number: "24", paid: false},
      {number: "25", paid: false},
      {number: "26", paid: false},
      {number: "27", paid: false},
      {number: "28", paid: false},
      {number: "29", paid: false},
      {number: "30", paid: false},
      {number: "31", paid: false},
      {number: "32", paid: false},
      {number: "33", paid: false},
      {number: "34", paid: false},
      {number: "35", paid: false},
      {number: "36", paid: false},
      {number: "37", paid: false},
      {number: "38", paid: false},
      {number: "39", paid: false},
      {number: "40", paid: false},
      {number: "41", paid: false},
      {number: "42", paid: false},
      {number: "43", paid: false},
      {number: "44", paid: false},
      {number: "45", paid: false},
      {number: "45А", paid: false},
      {number: "45Б", paid: false},
      {number: "46", paid: false},
      {number: "47", paid: false},
      {number: "48", paid: false},
      {number: "49", paid: false},
      {number: "50", paid: false},
      {number: "51", paid: false},
      {number: "52", paid: false},
      {number: "53", paid: false},
      {number: "54", paid: false},
      {number: "55", paid: false},
      {number: "56", paid: false},
      {number: "57", paid: false},
      {number: "58", paid: false},
      {number: "59", paid: false},
      {number: "60", paid: false},
      {number: "61", paid: false},
      {number: "62", paid: false},
      {number: "63", paid: false},
      {number: "64", paid: false},
      {number: "65", paid: false},
      {number: "66", paid: false},
      {number: "67", paid: false},
      {number: "68", paid: false},
      {number: "69", paid: false},
      {number: "70", paid: false},
      {number: "71", paid: false},
      {number: "72", paid: false},
      {number: "73", paid: false},
      {number: "74", paid: false},
      {number: "75", paid: false},
      {number: "76", paid: false},
      {number: "77", paid: false},
      {number: "78", paid: false},
      {number: "79", paid: false},
      {number: "80", paid: false},
      {number: "81", paid: false},
      {number: "82", paid: false},
      {number: "83", paid: false},
      {number: "84", paid: false},
      {number: "85", paid: false},
      {number: "86", paid: false},
      {number: "87", paid: false},
      {number: "88", paid: false},
      {number: "89", paid: false},
      {number: "90", paid: false},
      {number: "91", paid: false},
      {number: "92", paid: false},
      {number: "93", paid: false},
      {number: "94", paid: false},
      {number: "95", paid: false},
      {number: "96", paid: false},
      {number: "97", paid: false},
      {number: "98", paid: false},
      {number: "99", paid: false},
      {number: "100", paid: false},
      {number: "101", paid: false},
      {number: "102", paid: false},
      {number: "103", paid: false},
      {number: "104", paid: false},
      {number: "105", paid: false},
      {number: "106", paid: false},
      {number: "107", paid: false},
      {number: "108", paid: false},
      {number: "109", paid: false},
      {number: "110", paid: false},
      {number: "111", paid: false},
      {number: "112", paid: false},
      {number: "113", paid: false},
      {number: "114", paid: false},
      {number: "115", paid: false},
      {number: "116", paid: false},
      {number: "117", paid: false},
      {number: "118", paid: false},
      {number: "119", paid: false},
      {number: "120", paid: false},
      {number: "121А", paid: false},
      {number: "121Б", paid: false},
      {number: "122А", paid: false},
      {number: "122Б", paid: false},
      {number: "123А", paid: false},
      {number: "123Б", paid: false},
      {number: "124", paid: false},
      {number: "125", paid: false},
      {number: "126", paid: false},
      {number: "127", paid: false},
      {number: "128", paid: false},
      {number: "129", paid: false},
      {number: "130", paid: false},
      {number: "131", paid: false},
      {number: "132", paid: false},
      {number: "133", paid: false},
      {number: "134", paid: false},
      {number: "135", paid: false},
      {number: "136", paid: false},
      {number: "137", paid: false},
      {number: "138", paid: false},
      {number: "139", paid: false},
      {number: "140", paid: false},
      {number: "141", paid: false},
      {number: "142", paid: false},
      {number: "143", paid: false},
      {number: "144", paid: false},
      {number: "145", paid: false},
      {number: "146", paid: false},
      {number: "147", paid: false},
    ]
  },
  getters: {
    countPaid: (state) => {
      return state.houses.filter(house => house.paid).length
    },
    totalAmount: (state, getters) => {
      return getters.countPaid * state.amount
    }
  },
  mutations: {
    setPaid(state, number) {
        let house = state.houses.find((h) => number == h.number);
        house.paid = true
    },
    setStage(state, stage) {
      state.registration_open = 'open' == stage;
    }
  },
  actions: {
    setPaid(context, number) {
      context.commit('setPaid', number)
    },
    setStage(context, stage) {
      context.commit('setStage', stage)
    }
  },
  modules: {
  }
})
